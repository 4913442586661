module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"timsportfolio2021","schemas":{"welcome":{"Main":{"uid":{"type":"UID","config":{"label":"uid"}},"brood_text":{"type":"Group","config":{"fields":{"style":{"type":"Select","config":{"options":["nieuwe regel","in zelfde regel"],"label":"style"}},"text_link_of_space":{"type":"Select","config":{"options":["text","link","space"],"default_value":"text","label":"text link of space"}},"context":{"type":"StructuredText","config":{"multi":"paragraph","label":"context","placeholder":"hier alle content"}},"type_link":{"type":"Select","config":{"options":["mailto:","tel:","/","www."],"label":"type_link"}},"font_grootte":{"type":"Select","config":{"options":["small","big"],"default_value":"small","label":"font_grootte"}}},"label":"brood_text"}}}},"work":{"Main":{"uid":{"type":"UID","config":{"label":"uid"}},"order":{"type":"Number","config":{"label":"order"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"title"}},"date":{"type":"StructuredText","config":{"multi":"paragraph","label":"date"}},"live_link":{"type":"StructuredText","config":{"multi":"paragraph","label":"live_link"}},"repo_link":{"type":"StructuredText","config":{"multi":"paragraph","label":"repo_link"}},"cover":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"cover"}},"brood_text":{"type":"Group","config":{"fields":{"style":{"type":"Select","config":{"options":["nieuwe regel","in zelfde regel"],"label":"style"}},"text_link_of_space":{"type":"Select","config":{"options":["text","link","space"],"default_value":"text","label":"text link of space"}},"context":{"type":"StructuredText","config":{"multi":"paragraph","label":"context","placeholder":"hier alle content"}},"type_link":{"type":"Select","config":{"options":["mailto:","tel:","/","www."],"label":"type_link"}},"font_grootte":{"type":"Select","config":{"options":["small","big"],"default_value":"small","label":"font_grootte"}}},"label":"brood_text"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/timothy_name.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"68e818f1c69dbdc05436f299379a4dea"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
